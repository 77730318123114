module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/rabl.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"27bd7f26ad4dcfee6f1975f408627c0c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en","fr","it"],"defaultLanguage":"de","siteUrl":"https://rabl.tax","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/news/voranmeldung-ekz-1-verlaengerung/","languages":["de"]},{"matchPath":"/news/telearbeit-rahmenvereinbarung-oesterreich-slowakei/","languages":["de"]},{"matchPath":"/news/gesundheitsbonus-der-svs/","languages":["de"]},{"matchPath":"/news/pauschalfoerdermodell-energiekostenzuschuss-beschlossen/","languages":["de"]},{"matchPath":"/news/neuer-bmf-erlass-erhöhung-zinssaetze-ab-8-februar-2023/","languages":["de"]},{"matchPath":"/news/fristen-fuer-die-abgabe-der-steuererklaerung-2023/","languages":["de"]},{"matchPath":"/news/sozialversicherungsbonus-fuer-selbstaendige-und-landwirte-2022-08/","languages":["de"]},{"matchPath":"/news/neuer-bmf-erlass/","languages":["de"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://rabl.tax/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-KGFP9CD","cookieName":"rabl-tax-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
