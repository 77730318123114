exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-colleagues-js": () => import("./../../../src/pages/services/colleagues.js" /* webpackChunkName: "component---src-pages-services-colleagues-js" */),
  "component---src-pages-services-financial-accounts-and-tax-returns-js": () => import("./../../../src/pages/services/financial-accounts-and-tax-returns.js" /* webpackChunkName: "component---src-pages-services-financial-accounts-and-tax-returns-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-individuals-js": () => import("./../../../src/pages/services/individuals.js" /* webpackChunkName: "component---src-pages-services-individuals-js" */),
  "component---src-pages-services-international-tax-js": () => import("./../../../src/pages/services/international-tax.js" /* webpackChunkName: "component---src-pages-services-international-tax-js" */),
  "component---src-pages-services-social-security-js": () => import("./../../../src/pages/services/social-security.js" /* webpackChunkName: "component---src-pages-services-social-security-js" */),
  "component---src-pages-services-startups-js": () => import("./../../../src/pages/services/startups.js" /* webpackChunkName: "component---src-pages-services-startups-js" */),
  "component---src-pages-services-subsidies-js": () => import("./../../../src/pages/services/subsidies.js" /* webpackChunkName: "component---src-pages-services-subsidies-js" */),
  "component---src-templates-news-post-contentful-js": () => import("./../../../src/templates/news-post-contentful.js" /* webpackChunkName: "component---src-templates-news-post-contentful-js" */)
}

